/**
 * Slice para el maneja del estado para el componente Ficha cliente
 */
import { createSlice } from "@reduxjs/toolkit";

//Estado inicial de cada pestaña
const statusView = {
  general: true,
  datosop: false,
  condnego: false,
};

const fieldgridRowDirProv = {
  PAIIDXXX: "",
  DEPIDXXX: "",
  CIUIDXXX: "",
  APLVECIX: "",
  CLIPROAO: "",
  CLIPROCB: "",
  PIEIDXXX: "",
  PRODIRXX: "",
};

const fieldgridRowTipProd = {
  TIPPROID: "",
  BANCAPPE: "",
};

const fieldgridRowMod = {
  MODIDXXX: "",
};

const fieldgridRowDesc = {
  DESIDXXX: "",
};

const fieldgridRowPaisOr = {
  PAIIDXXX: "",
};

const fieldgridRowFiles = {
  PROLINKX: "",
};

const fieldgridRowPaisProd = {
  PAIIDXXX: "",
};

const fieldgridRowTermNeg = {
  TERMIDXX: "",
  LUGENTXX: "",
};

const fieldgridRowResol = {
  NUMRESXX: "",
  FECRESXX: "",
  TIPRESXX: "",
};

const fieldgridRowTipCont = {
  CONIDXXX: "",
  CLIPRONC: "",
  CLIPROFC: "",
  CLIPROVC: "",
  MONIDXXX: "",
  CLIPROBC: "",
};

const fieldgridTipCamF = {
  MONIDXXX: "",
  TIPCAMXX: "",
};

const fieldgridRowDIM = {
  LIMLEVXX: "",
  LIMFLEVX: "",
};

const fieldgridRowCodProd = {
  PROIDXXX: "",
};

const fieldgridProd = {
  PROIDXXX: "",
  CANPROXX: "",
  UNIIDXXX: "",
};

const initialState = {
  statusView,
  isLoadFrom: false,
  clientes: {},
  exDIAN: "",
  codNat: null,
  datentBPO: null,
  integracion: null,
  manual: null,
  codconven: null,
  showGridDir: false,
  gridRowDirProv: [fieldgridRowDirProv],
  gridRowTipProd: [fieldgridRowTipProd],
  gridRowMod: [fieldgridRowMod],
  manDesVar: null,
  showDescMin: false,
  gridRowDesc: [fieldgridRowDesc],
  condDIP: null,
  gridRowPaisOr: [fieldgridRowPaisOr],
  condRegPro: null,
  prodCondEsp: null,
  showCondProd: false,
  condProd: null,
  monNeg: null,
  aplSegCod: null,
  formaPago: null,
  entListEmp: null,
  pesbrItLisEmp: null,
  gridRowFiles: [fieldgridRowFiles],
  solInfCla: null,
  gridRowPaisProd: [fieldgridRowPaisProd],
  gridRowTermNeg: [fieldgridRowTermNeg],
  gridRowResol: [fieldgridRowResol],
  exNegGlob: null,
  showExNegGlob: false,
  gridRowTipCont: [fieldgridRowTipCont],
  exTipCamF: null,
  gridRowTipCamF: [fieldgridTipCamF],
  codForEnv: null,
  tranComP: null,
  tipPerson: null,
  showTipPersonNat: false,
  showTipPersonJur: false,
  razSocInter: "",
  paisInt: {},
  showDepInt: false,
  depInt: {},
  showCiuInt: false,
  ciuInt: {},
  tipInter: null,
  showEspTipInter: false,
  resCesMer: null,
  showResCesMer: false,
  codRest: null,
  conValMerc: null,
  showConValMerc: false,
  codConCont: null,
  showCodConCont: false,
  detValConCont: null,
  exCanDerLic: null,
  showExCanDerLic: false,
  aplReg: null,
  regDifMar: null,
  exContPagReg: null,
  exRevVen: null,
  exVinImpProv: null,
  showExVinImpProv: false,
  codVinImpProv: null,
  exVinPreMer: null,
  showExVinPreMer: false,
  exValCri: null,
  gridRowDIM: [fieldgridRowDIM],
  exPagIndDeRe: null,
  gridRowCodProd: [fieldgridRowCodProd],
  gridRowProd: [fieldgridProd],
  docCamFijo: null,
  statusProvider: null,
};

const fichprovSlice = createSlice({
  name: "fichprov",
  initialState: initialState,
  reducers: {
    setStatusShowView: (state, action) => {
      state.statusView = action.payload;
    },
    resetValues: (state) => initialState,
    setIsLoadFrom: (state, action) => {
      state.isLoadFrom = action.payload;
    },

    //Datos Generales Proveedor
    setClientes: (state, action) => {
      state.clientes = action.payload;
    },
    setDatentBPO: (state, action) => {
      state.datentBPO = action.payload;
    },
    setIntegracion: (state, action) => {
      state.integracion = action.payload;
    },
    setManual: (state, action) => {
      state.manual = action.payload;
    },
    setCodConVen: (state, action) => {
      state.codconven = action.payload;
    },
    setShowGridDir: (state, action) => {
      state.showGridDir = action.payload;
    },
    setGridRowDirProv: (state, action) => {
      state.gridRowDirProv = action.payload;
    },
    //Fin Datos Generales Proveedor

    //Datos de Operacion
    setGridRowTipProd: (state, action) => {
      state.gridRowTipProd = action.payload;
    },
    setGridRowMod: (state, action) => {
      state.gridRowMod = action.payload;
    },
    setManDesVar: (state, action) => {
      state.manDesVar = action.payload;
    },
    setShowDescMin: (state, action) => {
      state.showDescMin = action.payload;
    },
    setGridRowDesc: (state, action) => {
      state.gridRowDesc = action.payload;
    },
    setCondDIP: (state, action) => {
      state.condDIP = action.payload;
    },
    setGridRowPaisOr: (state, action) => {
      state.gridRowPaisOr = action.payload;
    },
    setCondRegPro: (state, action) => {
      state.condRegPro = action.payload;
    },
    setProdCondEsp: (state, action) => {
      state.prodCondEsp = action.payload;
    },
    setShowCondProd: (state, action) => {
      state.showCondProd = action.payload;
    },
    setCondProd: (state, action) => {
      state.condProd = action.payload;
    },
    setMonNeg: (state, action) => {
      state.monNeg = action.payload;
    },
    setAPliSegCod: (state, action) => {
      state.aplSegCod = action.payload;
    },
    setFormaPago: (state, action) => {
      state.formaPago = action.payload;
    },
    setEntListEmp: (state, action) => {
      state.entListEmp = action.payload;
    },
    setPesBrItLisEmp: (state, action) => {
      state.pesbrItLisEmp = action.payload;
    },
    setGridRowFiles: (state, action) => {
      state.gridRowFiles = action.payload;
    },
    setSolInfCla: (state, action) => {
      state.solInfCla = action.payload;
    },
    setGridRowPaisProd: (state, action) => {
      state.gridRowPaisProd = action.payload;
    },
    setGridRowTermNeg: (state, action) => {
      state.gridRowTermNeg = action.payload;
    },
    //Fin Datos de Operacion

    //Condiciones de Negociación
    setExDIAN: (state, action) => {
      state.exDIAN = action.payload;
    },
    setGridRowResol: (state, action) => {
      state.gridRowResol = action.payload;
    },
    setCodNat: (state, action) => {
      state.codNat = action.payload;
    },
    setExNegGlob: (state, action) => {
      state.exNegGlob = action.payload;
    },
    setShowExNegGlob: (state, action) => {
      state.showExNegGlob = action.payload;
    },
    setGridRowTipCont: (state, action) => {
      state.gridRowTipCont = action.payload;
    },
    setExTipCamF: (state, action) => {
      state.exTipCamF = action.payload;
    },
    setGridRowTipCamF: (state, action) => {
      state.gridRowTipCamF = action.payload;
    },
    setCodForEnv: (state, action) => {
      state.codForEnv = action.payload;
    },
    setTranComP: (state, action) => {
      state.tranComP = action.payload;
    },
    setTipPerson: (state, action) => {
      state.tipPerson = action.payload;
    },
    setShowTipPersonNat: (state, action) => {
      state.showTipPersonNat = action.payload;
    },
    setShowTipPersonJur: (state, action) => {
      state.showTipPersonJur = action.payload;
    },
    setRazSocInter: (state, action) => {
      state.razSocInter = action.payload;
    },
    setPaisInt: (state, action) => {
      state.paisInt = action.payload;
    },
    setShowDepInt: (state, action) => {
      state.showDepInt = action.payload;
    },
    setDepInt: (state, action) => {
      state.depInt = action.payload;
    },
    setShowCiuInt: (state, action) => {
      state.showCiuInt = action.payload;
    },
    setCiuInt: (state, action) => {
      state.ciuInt = action.payload;
    },
    setTipInter: (state, action) => {
      state.tipInter = action.payload;
    },
    setShowEspTipInter: (state, action) => {
      state.showEspTipInter = action.payload;
    },
    setResCesMer: (state, action) => {
      state.resCesMer = action.payload;
    },
    setShowResCesMer: (state, action) => {
      state.showResCesMer = action.payload;
    },
    setCodRest: (state, action) => {
      state.codRest = action.payload;
    },
    setConValMerc: (state, action) => {
      state.conValMerc = action.payload;
    },
    setShowConValMerc: (state, action) => {
      state.showConValMerc = action.payload;
    },
    setCodConCont: (state, action) => {
      state.codConCont = action.payload;
    },
    setShowCodConCont: (state, action) => {
      state.showCodConCont = action.payload;
    },
    setDetValConCont: (state, action) => {
      state.detValConCont = action.payload;
    },
    setExCanDerLic: (state, action) => {
      state.exCanDerLic = action.payload;
    },
    setShowExCanDerLic: (state, action) => {
      state.showExCanDerLic = action.payload;
    },
    setAplReg: (state, action) => {
      state.aplReg = action.payload;
    },
    setRegDifMar: (state, action) => {
      state.regDifMar = action.payload;
    },
    setExContPagReg: (state, action) => {
      state.exContPagReg = action.payload;
    },
    setExRevVen: (state, action) => {
      state.exRevVen = action.payload;
    },
    setExVinImpProv: (state, action) => {
      state.exVinImpProv = action.payload;
    },
    setShowExVinImpProv: (state, action) => {
      state.showExVinImpProv = action.payload;
    },
    setCodVinImpProv: (state, action) => {
      state.codVinImpProv = action.payload;
    },
    setExVinPreMer: (state, action) => {
      state.exVinPreMer = action.payload;
    },
    setShowExVinPreMer: (state, action) => {
      state.showExVinPreMer = action.payload;
    },
    setExValCri: (state, action) => {
      state.exValCri = action.payload;
    },
    setGridRowDIM: (state, action) => {
      state.gridRowDIM = action.payload;
    },
    setExPagIndDeRe: (state, action) => {
      state.exPagIndDeRe = action.payload;
    },
    setGridRowCodProd: (state, action) => {
      state.gridRowCodProd = action.payload;
    },
    setGridRowProd: (state, action) => {
      state.gridRowProd = action.payload;
    },
    setDocCamFijo: (state, action) => {
      state.docCamFijo = action.payload;
    },
    setStatusProvider: (state, action) => {
      state.statusProvider = action.payload;
    },
    //Fin Condiciones de Negociación
  },
});

export const {
  setStatusShowView,
  resetValues,
  setIsLoadFrom,
  setClientes,
  setDatentBPO,
  setIntegracion,
  setManual,
  setCodConVen,
  setShowGridDir,
  setGridRowDirProv,
  setExDIAN,
  setCodNat,
  setGridRowTipProd,
  setGridRowMod,
  setManDesVar,
  setShowDescMin,
  setGridRowDesc,
  setCondDIP,
  setGridRowPaisOr,
  setCondRegPro,
  setProdCondEsp,
  setShowCondProd,
  setCondProd,
  setMonNeg,
  setAPliSegCod,
  setFormaPago,
  setEntListEmp,
  setPesBrItLisEmp,
  setGridRowFiles,
  setSolInfCla,
  setGridRowPaisProd,
  setGridRowTermNeg,
  setGridRowResol,
  setExNegGlob,
  setShowExNegGlob,
  setGridRowTipCont,
  setExTipCamF,
  setGridRowTipCamF,
  setCodForEnv,
  setTranComP,
  setTipPerson,
  setShowTipPersonNat,
  setShowTipPersonJur,
  setRazSocInter,
  setPaisInt,
  setDepInt,
  setShowDepInt,
  setShowCiuInt,
  setCiuInt,
  setTipInter,
  setShowEspTipInter,
  setResCesMer,
  setShowResCesMer,
  setCodRest,
  setConValMerc,
  setShowConValMerc,
  setCodConCont,
  setShowCodConCont,
  setDetValConCont,
  setExCanDerLic,
  setShowExCanDerLic,
  setAplReg,
  setRegDifMar,
  setExContPagReg,
  setExRevVen,
  setExVinImpProv,
  setShowExVinImpProv,
  setCodVinImpProv,
  setExVinPreMer,
  setShowExVinPreMer,
  setExValCri,
  setGridRowDIM,
  setExPagIndDeRe,
  setGridRowCodProd,
  setGridRowProd,
  setDocCamFijo,
  setStatusProvider,
} = fichprovSlice.actions;

export default fichprovSlice.reducer;

export const getStatusShowView = (state) => {
  return state.fichprov.statusView;
};

export const getAllState = (state) => {
  return state.fichprov;
};

export const getStatusProvider = (state) => {
  return state.fichprov.statusProvider;
};
