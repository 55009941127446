/*
 * Pagina del Dashboard: Permite navegar dentro de la aplicacion
 * Muestra la barra de navegacion, el acceso al perfil y renderiza el modulo y proceso seleccionado
 */

import {
  Dashboard,
  DashboardMain,
  MenuWrapper,
  MenuItems,
  HeaderBoxLeft,
  HeaderBoxRight,
  HeaderWrapper,
  HeaderSearch,
  HeaderUser,
  HeaderUserItem,
  Divider,
} from "codekit";

import DashboardChildRoute from "./DashboardChildRoute";
import MyProfile from "./MyProfile";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Floatool from "../components/Floatool";

import {
  getDataUser,
  getMenuUser,
  signOut,
  setMenuUser,
  setModuleURL,
  getIsReplaceUser,
  getInfoUserReplace,
} from "../libs/redux/slices/authSlice/authSlice";
import {
  getModuleSelected,
  reset,
  setModuleSelected,
  setProcesSelected,
} from "../libs/redux/slices/MenuSlice/menuSlice";
import { useLogoutMutation } from "../libs/redux/slices/authSlice/authApiSlice";
import { useOrderMenuMutation } from "../libs/redux/slices/MenuSlice/menuApliSlice";

import capitalizeWord from "../utils/capitalize-words";
import generateAvatarLetters from "../utils/avatar-letters-generator";
import { resetConsult } from "../libs/redux/slices/consultSlice/consultSlice";
import { resetCustomFilters } from "../libs/redux/slices/trackingSlice/trackingSlice";
import { Notificx } from "./Notificx";

import { resetFiltersGraph } from "../libs/redux/slices/Graficsx/graficsxSlice";
import Usertmpx from "./Usertmpx";

const DashboardPage = () => {
  const dispatch = useDispatch();

  const userData = useSelector(getDataUser);
  const menuData = useSelector(getMenuUser);

  const { MODURLXX } = useSelector(getModuleSelected);
  const { USREMAXX, USRNOMXX, USRIDXXX, USRURLFO } = userData;

  const [orderMenu] = useOrderMenuMutation();
  const [logout] = useLogoutMutation();

  const [menuDataMap, setMenuDataMap] = useState([]);
  const [showMyProfile, setShowMyProfile] = useState(false);
  const isReplaceUser = useSelector(getIsReplaceUser);
  const [infoUser, setInfoUser] = useState({ USREMAXX, USRNOMXX, USRIDXXX, USRURLFO });
  const infoUserReplace = useSelector(getInfoUserReplace);

  /**
   * Funcion para guardar el modulo y proceso elegido del menu de navgacion
   */
  const onSelectOption = (moduleItem, processItem) => {
    dispatch(setModuleSelected(moduleItem));
    dispatch(setProcesSelected(processItem));
    setShowMyProfile(false);
  };

  /**
   * Hook que establece los elementos del menu de navegacion y actualiza su estado activo cuando se seleccionan
   */
  useEffect(() => {
    /**
     * Funcion que genera los items del menu de navegacion
     */
    const menuDataMapper = () => {
      return menuData.map((menu) => {
        let isActive = false;

        if (MODURLXX) {
          isActive = menu.MODURLXX === MODURLXX ? true : false;
        }

        const menuDataItem = {
          id: menu.MODIDXXX,
          description: menu.MODDESXX,
          url: menu.MODURLXX,
          icon: `${menu.MODICOXX}`,
          isActive: isActive,
          items: menu.CIMS0004.map((subItem) => ({
            id: subItem.PROIDXXX,
            description: subItem.PRODESXX,
            url: subItem.PRODURLX,
            onClick: () => {
              onSelectOption(menu, subItem);
            },
          })),
        };
        return menuDataItem;
      });
    };

    const menuDataMap = menuDataMapper();

    setMenuDataMap(menuDataMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MODURLXX, menuData, dispatch]);

  /**
   * Hook que setea la URL del modulo para la cabecera de la peticion
   */
  useEffect(() => {
    if (MODURLXX) {
      dispatch(setModuleURL(MODURLXX.toLowerCase()));
    }
  }, [MODURLXX, dispatch]);

  /**
   * Funcion para cerrar sesion del usuario
   */
  const onLogout = async () => {
    await logout();
    dispatch(signOut());
    dispatch(reset());
    dispatch(resetConsult());
    dispatch(resetCustomFilters());
    dispatch(resetFiltersGraph());
    localStorage.clear();
  };

  /**
   * Funcion que vacia el modulo y proceso elegido
   */
  const handleClickHome = () => {
    dispatch(setModuleSelected(""));
    dispatch(setProcesSelected(""));
    setShowMyProfile(false);
  };

  /**
   * Funcion para reordenar los elementos del menu
   */
  const reorderMenuData = (context) => {
    const indexes = context.item.indexes;
    const newIndex = indexes.new;
    const previousIndex = indexes.previous;

    const newMenuData = [...menuData];
    const [removed] = newMenuData.splice(previousIndex, 1);
    newMenuData.splice(newIndex, 0, removed);
    return newMenuData;
  };

  /**
   * Funcion que sirve para guardar el nuevo orden del menu
   */
  const setNewMenuOrder = async (newDataMenu) => {
    const data = newDataMenu.map((module) => {
      return module.MODIDXXX;
    });
    await orderMenu({ MODIDXXX: data }).unwrap();
  };

  /**
   * Funcion que maneja el arrastrado de los items del menu
   */
  const handleMenuDragging = (context) => {
    // Set new menuDataMap order
    const items = context._newItems;
    setMenuDataMap(items);

    // Set new menuData order
    const newMenuData = reorderMenuData(context);
    dispatch(setMenuUser(newMenuData));
    setNewMenuOrder(newMenuData);
  };

  //Hook que controla el cambio de informacion para el usuario reemplazo
  useEffect(() => {
    if (Object.entries(infoUserReplace).length > 0) {
      setInfoUser({ ...infoUserReplace });
    }
  }, [infoUserReplace]);

  return (
    <Dashboard>
      <HeaderWrapper>
        <HeaderBoxLeft>
          <HeaderSearch />
        </HeaderBoxLeft>
        <HeaderBoxRight>
          <Notificx />
          <Floatool onSelectOption={onSelectOption} />
          <HeaderUser
            image={`${
              infoUser.USRURLFO && infoUser.USRURLFO !== ""
                ? infoUser?.USRURLFO
                : generateAvatarLetters(`${infoUser.USRNOMXX}`)
            }`}
            name={capitalizeWord(infoUser.USRNOMXX)}
            email={infoUser.USREMAXX.toLowerCase()}
            isReplace={isReplaceUser}
          >
            <HeaderUserItem icon="user" onClick={() => setShowMyProfile(true)}>
              Mi Perfil
            </HeaderUserItem>
            <Usertmpx handleClickHome={handleClickHome} />
            <Divider />
            <HeaderUserItem icon="logout" onClick={onLogout}>
              Cerrar sesión
            </HeaderUserItem>
          </HeaderUser>
        </HeaderBoxRight>
      </HeaderWrapper>
      <MenuWrapper>
        <HeaderUser
          image={`${
            infoUser.USRURLFO && infoUser.USRURLFO !== ""
              ? infoUser?.USRURLFO
              : generateAvatarLetters(`${infoUser.USRNOMXX}`)
          }`}
          name={capitalizeWord(infoUser.USRNOMXX)}
          email={infoUser.USREMAXX.toLowerCase()}
          isInsideMenu
          isReplace={isReplaceUser}
        >
          <HeaderUserItem icon="user" onClick={() => setShowMyProfile(true)}>
            Mi Perfil
          </HeaderUserItem>
          <Usertmpx handleClickHome={handleClickHome} />
          <HeaderUserItem icon="logout" onClick={onLogout}>
            Cerrar sesión
          </HeaderUserItem>
        </HeaderUser>
        <MenuItems
          labelHome="Inicio"
          onClickHome={handleClickHome}
          onMenuDragged={handleMenuDragging}
          items={menuDataMap}
        />
      </MenuWrapper>
      <DashboardMain>{showMyProfile ? <MyProfile /> : <DashboardChildRoute />}</DashboardMain>
    </Dashboard>
  );
};

export default DashboardPage;
