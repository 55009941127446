import React, { useEffect, useState } from "react";
import { FloatingToolbox } from "codekit";
import { useSelector, useDispatch } from "react-redux";
import { setFunctionCall, getDraw } from "../../libs/redux/slices/Floatool/floatoolSlice";
import { getDataUser, getMenuUser } from "../../libs/redux/slices/authSlice/authSlice";
import { useGetMyPreferencesByUserQuery } from "../../libs/redux/slices/MyprefexSlice/myprefexApiSlice";

const Floatool = ({ onSelectOption }) => {
  const dispatch = useDispatch();
  const userData = useSelector(getDataUser);
  const dataMenu = useSelector(getMenuUser);
  const isDraw = useSelector(getDraw);
  const [buttons, setButtons] = useState([]);
  const { data, isSuccess } = useGetMyPreferencesByUserQuery(userData.USRIDXXX);
  useEffect(() => {
    if (isSuccess || isDraw) {
      const isPresent = (MODIDXXX, PROIDXXX, MENIDXXX) => {
        let present = data.data.filter(
          (option) =>
            option.MODIDXXX === MODIDXXX &&
            option.PROIDXXX === PROIDXXX &&
            option.MENIDXXX === MENIDXXX &&
            option.USRBANXX === "SI"
        );
        return present.length === 1 ? true : false;
      };

      let finalButtons = [];
      dataMenu.forEach((module) => {
        module.CIMS0004.forEach((process) => {
          process.CIMS0005.forEach((optionMenu) => {
            if (optionMenu.MENAPLBA === "SI" && isPresent(module.MODIDXXX, process.PROIDXXX, optionMenu.MENIDXXX)) {
              let optionSelected = {
                icon: optionMenu.MENUICOX,
                tooltip: optionMenu.OPCDESXX,
                variant: "secondary",
                onClick: () => {
                  onSelectOption(module, process);
                  dispatch(
                    setFunctionCall(
                      optionMenu.MENFUNXX !== undefined && optionMenu.MENFUNXX !== "" && optionMenu.MENFUNXX !== null
                        ? optionMenu.MENFUNXX
                        : ""
                    )
                  );
                },
              };
              finalButtons.push(optionSelected);
            }
          });
        });
      });
      setButtons(finalButtons);
    }
  }, [data?.data, dataMenu, dispatch, isSuccess, onSelectOption, isDraw]);

  return <FloatingToolbox buttons={buttons} />;
};

export default Floatool;
