import { apiSlice } from "../apiSlice";

export const dataTrackingSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getDataTracking: builder.query({
      query: (parameters) => ({
        url: `/${parameters.MODURLXX}/${parameters.PRODURLX}`,
        params: { ...parameters },
      }),
      providesTags: ["Create"],
    }),
    hideColumns: builder.mutation({
      query: (parameters) => ({
        url: `/columns/${parameters.url.MODIDXXX}/${parameters.url.PROIDXXX}/${parameters.url.NOMCOLXX}`,
        method: "PUT",
        body: {
          ...parameters.body,
        },
      }),
    }),
    getHideColumnsUser: builder.mutation({
      query: (parameters) => ({
        url: `/columns/${parameters.MODIDXXX}/${parameters.PROIDXXX}`,
        method: "GET",
      }),
    }),
    getDataHistory: builder.mutation({
      query: (parameters) => ({
        url: `/Gestorlog/Serchlog`,
        method: "POST",
        body: { ...parameters },
      }),
    }),
    sortColumns: builder.mutation({
      query: (parameters) => ({
        url: `/orderxxx/${parameters.url.MODIDXXX}/${parameters.url.PROIDXXX}`,
        method: "PUT",
        body: {
          ...parameters.body,
        },
      }),
    }),
    getSortColumnsUser: builder.mutation({
      query: (parameters) => ({
        url: `/orderxxx/${parameters.MODIDXXX}/${parameters.PROIDXXX}`,
        method: "GET",
      }),
    }),
  }),
});
export const { useGetDataTrackingQuery } = dataTrackingSlice;
export const { useHideColumnsMutation } = dataTrackingSlice;
export const { useGetHideColumnsUserMutation } = dataTrackingSlice;
export const { useGetDataHistoryMutation } = dataTrackingSlice;
export const { useSortColumnsMutation } = dataTrackingSlice;
export const { useGetSortColumnsUserMutation  } = dataTrackingSlice;
