import { createSlice } from "@reduxjs/toolkit";

const floatoolSlice = createSlice({
  name: "floatool",
  initialState: {
    functionCall: "",
    dataPreSelected: [],
    draw: false,
  },
  reducers: {
    setFunctionCall: (state, action) => {
      state.functionCall = action.payload;
    },
    setDataPreSelected: (state, action) => {
      state.dataPreSelected.push(action.payload);
    },
    setDraw: (state, action) => {
      state.draw = action.payload;
    },
    reset: (state) => {
      state.functionCall = "";
      state.draw = false;
    },
  },
});

export const { setFunctionCall, reset, setDraw } = floatoolSlice.actions;
export default floatoolSlice.reducer;

export const getFunctionCall = (state) => {
  return state.floatool.functionCall;
};

export const getDataPreSelected = (state) => {
  return state.floatool.dataPreSelected;
};

export const getDraw = (state) => {
  return state.floatool.draw;
};
